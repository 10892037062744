import React, {useEffect} from "react";
import { useLocation } from '@reach/router'

const SchemaMarkup = () => {
    const location = useLocation();
    const urls = ["/categories/artificial-intelligence", "/categories/data-science-and-analytics"
                    , "/categories/deep-learning", "/categories/machine-learning"
                    , "/categories/resume-parser"
                ]

    useEffect(() => {
        try {
            addScriptTagTypeBreadcrumbList();
            if(isArticleBlog())
                addScriptTagTypeArticle();
            if(location.pathname === "/blog" || location.pathname === "/blog/")
                addScriptTagTypeArticleBlog();
            if(urls.some(keyword => location.pathname.includes(keyword)))
                addScriptTagTypeArticleCategories();
        } catch (error) {
            console.error("Error:", error.message);
        }
    }, []);
  
    // check the current page is article bog or not
    const isArticleBlog = () => {
        if (location.pathname.startsWith("/blog/")) {
        var s = location.pathname.substring("/blog/".length);
        var regex = /^[0-9]+$/;
        if(s.length > 0 && regex.test(s) == false)
            return true;
        return false;
        }
        return false;
    }

    // add script tag type BreadcrumbList
    const addScriptTagTypeBreadcrumbList = () => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "application/ld+json";
        scriptTag.innerHTML = JSON.stringify({
        "@context": "https://schema.org/", 
        "@type": "BreadcrumbList", 
        "itemListElement": getItemListElement()
        });
    
        document.head.appendChild(scriptTag);
    }

    const getItemListElement = () => {
        var itemListElement = [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "Home",
        "item": "https://www.neurond.com/"  
        }]

        var pathname = location.pathname;
        if(pathname.length > 1) {
        var segments = pathname.substring(1).split("/");
        for( let i = 0; i < segments.length; i++){ 
            var item = {
            "@type": "ListItem", 
            "position": i + 2, 
            "name": i === 0 ? segments[i].charAt(0).toUpperCase() + segments[i].slice(1) : getNameOfItem(segments[i]),
            "item": getUrlItem(segments, i) 
            }
            itemListElement.push(item);
        }
        }
        return itemListElement
    }

    const getNameOfItem = (segment) => {
        var headingTitle = document.getElementById("heading-title");
        var headingTitleBlog = document.getElementById("heading-title-blog");
        var headingTitleSpans = document.getElementById("title-h1-include-span-tag");
        
        if (headingTitle && headingTitle.innerText.length > 0) {
            return customName(headingTitle.innerText, segment);
        }

        if (headingTitleBlog && headingTitleBlog.innerText.length > 0) {
            return customName(headingTitleBlog.innerText, segment);
        }

        if (headingTitleSpans && headingTitleSpans.innerText.length > 0) {
            var spanTags = headingTitleSpans.getElementsByTagName("span");
            
            var result = "";
            for (var i = 0; i < spanTags.length; i++) {
                result += " " +  spanTags[i].innerText;
            }

            return customName(result, segment);
        }

        return "";
    }

    const customName = (name, segment) => {
        var regex = /^[0-9]+$/;
        if (regex.test(segment)) 
            name = name.trim() + " Page " + segment;
        return name;
    }

    const getUrlItem = (segments, index) => {
        var result = "https://www.neurond.com";
        if (index <= segments.length){
        for(let i = 0; i <= index; i++){
            result += "/" + segments[i];
        }
        }
        return result;
    }

    // add script tag type Article
    const addScriptTagTypeArticle = () => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "application/ld+json";
        scriptTag.innerHTML = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.neurond.com" + location.pathname
        },
        "headline": getNameOfItem(""),
        "image": getFirstImage(),  
        "author": {
            "@type": "Person",
            "name": "Trinh Nguyen"
        },  
        "publisher": {
            "@type": "Organization",
            "name": "Neurond",
            "logo": {
            "@type": "ImageObject",
            "url": "https://cdn.neurond.com/neurondasset/assets/image/icon/neurond-final-white.svg"
            }
        },
        "datePublished": "2023-10-27"
        });
    
        document.head.appendChild(scriptTag);
    }

    const getFirstImage = () => {
        var logoNeurond = "https://cdn.neurond.com/neurondasset/assets/image/icon/neurond-final-white.svg";
        var pictureElements = document.getElementsByTagName("picture");
        if(pictureElements.length == 0)
            return logoNeurond;

        var imgElements = pictureElements[0].getElementsByTagName("img");
        if(imgElements.length == 0)
            return logoNeurond;;
        
        return "https://www.neurond.com/" + imgElements[0].getAttribute("src")
    }

    // add script tag type Article - Blog
    const addScriptTagTypeArticleBlog = () => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "application/ld+json";
        scriptTag.innerHTML = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.orientsoftware.com/blog/"
        },
        "headline": "Neurond Blog",
        "image": "https://www.neurond.com/static/banner-layer1-c18f64e1a04e44a985742321a6f01362.png",  
        "author": {
            "@type": "Person",
            "name": "Neurond Teams"
        },  
        "publisher": {
            "@type": "Organization",
            "name": "Neurond",
            "logo": {
            "@type": "ImageObject",
            "url": "https://cdn.neurond.com/neurondasset/assets/image/icon/neurond-final-white.svg"
            }
        },
        "datePublished": ""
        });

        document.head.appendChild(scriptTag);
    }

    // add script tag type Article - categories
    const addScriptTagTypeArticleCategories = () => {
        const scriptTag = document.createElement("script");
        scriptTag.type = "application/ld+json";
        scriptTag.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.neurond.com" + location.pathname
            },
            "headline": getNameOfItem("") + " Blog | Neurond AI",
            "image": getFirstImageOfCategoriesPage(),  
            "author": {
              "@type": "Organization",
              "name": "Neurond Teams"
            },  
            "publisher": {
              "@type": "Organization",
              "name": "Neurond",
              "logo": {
                "@type": "ImageObject",
                "url": "https://cdn.neurond.com/neurondasset/assets/image/icon/neurond-final-white.svg"
              }
            },
            "datePublished": ""
          });
        document.head.appendChild(scriptTag);
    }

    const getFirstImageOfCategoriesPage = () => {
        var logoNeurond = "https://cdn.neurond.com/neurondasset/assets/image/icon/neurond-final-white.svg";
        
        const blogZoneSection = document.querySelector("section.blog-zone");
        const categoriesBannerSection = blogZoneSection.querySelector("section.categoriesBanner");

        if (categoriesBannerSection) {
            const backgroundImage = window.getComputedStyle(categoriesBannerSection).getPropertyValue('background-image');

            const matches = backgroundImage.match(/url\("?(.*?)"?\)/);

            if (matches && matches[1]) {
                return matches[1];
            }
        }
        return logoNeurond;
    }

    return <></>
}

export default SchemaMarkup;